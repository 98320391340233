import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, Grid, Snackbar, Typography, useMediaQuery, useTheme  } from '@mui/material';
import { sendEmailVerification } from 'firebase/auth';

import makeStyles from '@mui/styles/makeStyles';

import { useAuth } from 'app/ProviderAuth';

import { Button, NexLoadingIcon } from 'components';

import { Company, Referrer } from 'types';

import { ReferrerDetails } from '.';
import { ReferrerDetailsWhole } from '.';
import { QueryDocumentSnapshot, QuerySnapshot } from 'firebase/firestore';
import { queryCompaniesByCompany, queryReferrersByCompanyId } from 'apis';

const useStyles = makeStyles((theme) => ({
  header: {
    marginTop: '4%',
    marginBottom: '4%',
  },
  title: {
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '47px',
    color: '#000000',
    textAlign: 'left',
    marginTop: '7px',
  },
  messagePosition: {
    top: '50%',
    left: '45%',
    position: 'absolute',
  },
  companyLogo: {
    height: 70,
    width: 70,
    objectFit: 'contain',
  },
  hr: {
    marginTop: '20px',
  },
}));

export const CompanyPage = () => {
  const { user } = useAuth();
  const classes = useStyles();
  const navigate = useNavigate();
  const { companyName } = useParams<{ companyName: string }>();
  const [linearProgress, setLinearProgress] = React.useState(true);
  const [company, setCompany] = React.useState<Company>();
  const [referrers, setReferrers] = React.useState<Referrer[]>();
  const [emailActivationSnackbar, setEmailActivationSnackbar] =
    React.useState(false);
  const theme = useTheme();
  const isWholeView = useMediaQuery(theme.breakpoints.up(991));
  React.useEffect(() => {
    const fetchData = async () => {
      if (!companyName) {
        return;
      }
      const matches = await queryCompaniesByCompany(
        decodeURIComponent(companyName)
      );
      if (matches.empty) {
        return;
      }

      const c = matches.docs.at(0);
      if (!c) {
        return;
      }
      setCompany(c.data());
      const referrersSnapshot = await queryReferrersByCompanyId(c.data().id!);
      const referrers =
        referrersSnapshot.docs
          .map((doc) => doc.data())
          .filter(
            (referrer) =>
              referrer.isActivated || referrer.isActivated === undefined
          ) ?? [];
      setReferrers(referrers);

      setLinearProgress(false);
    };

    fetchData();
  }, [companyName]);

  const handleOnButtonClick = (id: string) => {
    if (!user) {
      navigate('/signin');
      return;
    }

    if (user.emailVerified) {
      navigate(`/companies/${encodeURIComponent(companyName!)}/referrers/${id}/apply`);
      return;
    }

    setEmailActivationSnackbar(true);
  };

  const renderReferrer = (referrer: Referrer) => {
    return (
      <Grid item  key={`referrer-${referrer.uid}`} alignItems='center'
        sx = {{
          width: isWholeView ? 'inherit': 'fit-content',
        }}
      >
        {isWholeView ?
        <ReferrerDetailsWhole
          referrer={referrer}
          onButtonClick={() => handleOnButtonClick(referrer.uid)} />
        : <ReferrerDetails
          referrer={referrer}
          onButtonClick={() => handleOnButtonClick(referrer.uid)}
        />}
      </Grid>
    );
  };


  const sendActivationEmail = () => {
    if (!user) {
      return;
    }
    sendEmailVerification(user);
    setEmailActivationSnackbar(false);
  };

  if (linearProgress) {
    return <NexLoadingIcon />;
  }

  return (
    <>
      <Helmet>
        <title>{`選擇 ${companyName} 內推夥伴 | NEX Work`}</title>
        <meta
          name="description"
          content={`這裡是 ${companyName} 推薦人頁面，你可以在這裡找到公司裡願意配合內推的台灣前輩資料`}
        />
      </Helmet>
      <Container maxWidth="lg">
        <Grid container className={classes.header}>
          <Grid item lg={1} md={1} xs={12}>
            <img
              src={company?.logoUrl || '/defaultCompanyLogo.png'}
              alt={company?.company}
              className={classes.companyLogo}
            />
          </Grid>
          <Grid item lg={11} md={11} xs={12}>
            <Typography gutterBottom className={classes.title}>
              請選擇推薦人幫你內推 {company?.company?.toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              這裡是 NEX WORK
              推薦人頁面，你可以在這裡找到公司裡願意配合內推的台灣前輩資料，可以找和自己想申請的圑隊較為接近的前輩幫你內推，但不鼓勵大家一信多投；最後提醒大家一定要看清楚內文和推薦人的要求再送出自己的履歷。
            </Typography>
          </Grid>
        </Grid>
      </Container>

      {referrers && referrers.length > 0 ?  (
        <Container maxWidth="lg" >
          <Grid container  columnSpacing = {2} rowSpacing= {3} justifyContent="center" alignItems={"stretch"} marginBottom={5}>
            {referrers.map(renderReferrer)}
          </Grid>
        </Container>
      ) : (
        <Container>
          <Typography gutterBottom className={classes.messagePosition}>
            目前沒有推薦人
          </Typography>
        </Container>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        key="snackbar-message"
        open={emailActivationSnackbar}
        aria-describedby="snackbar-message"
        message="麻煩請先認證您的Email"
        action={
          <Button color="secondary" onClick={sendActivationEmail}>
            重新寄送認證信
          </Button>
        }
      />
    </>
  );
};