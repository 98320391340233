import { Timestamp } from '@firebase/firestore-types';
import { Conversation } from 'types';

export const getTimeFromFireStore = (timestamp: Timestamp) => {
  const time = !timestamp ? new Date() : new Date(timestamp.seconds * 1000);
  // Nov 6
  return `${time
    .toLocaleString('default', {
      month: 'long',
    })
    .substring(0, 3)} ${time.getDate()}`;
};

export const getTimeWithYearFromFireStore = (timestamp: Timestamp) => {
  const time = !timestamp ? new Date() : new Date(timestamp.seconds * 1000);
  const year = time.getFullYear();
  const month = String(time.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(time.getDate()).padStart(2, '0');
  return `${year}/${month}/${day}`;
};

export const stringAvatar = (name: string) => {
  const names = name.split(' ');
  return {
    children: names.map((n) => n[0]),
  };
};

export const getReceiver = (conversation: Conversation, userId: string) => {
  const index = conversation.participantsId.findIndex(
    (item) => item !== userId
  );
  return conversation.participantsName[index];
};
