import { referrersCollection } from 'configs/db';
import {
  setDoc,
  doc,
  DocumentSnapshot,
  getDoc,
  getDocs,
  query,
  QuerySnapshot,
  serverTimestamp,
  updateDoc,
  where,
  orderBy,
  Timestamp,
} from 'firebase/firestore';
import { Referrer } from 'types';

export const queryReferrers = async (
  sortField?: string,
  direction: 'asc' | 'desc' = 'asc'
): Promise<QuerySnapshot<Referrer>> => {
  const q = sortField
    ? query(referrersCollection, orderBy(sortField, direction))
    : query(referrersCollection);
  return await getDocs(q);
};

export const getReferrer = async (
  id: string
): Promise<DocumentSnapshot<Referrer>> =>
  await getDoc(doc(referrersCollection, id));

export const createReferrer = async (data: Referrer): Promise<void> =>
  await setDoc(doc(referrersCollection, data.uid), {
    ...data,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });

export const updateReferrer = async (
  id: string,
  data: Referrer
): Promise<void> =>
  await updateDoc(doc(referrersCollection, id), {
    ...data,
    updatedAt: serverTimestamp(),
  });

export const queryReferrersByUid = async (
  uid: string
): Promise<QuerySnapshot<Referrer>> =>
  await getDocs(query(referrersCollection, where('uid', '==', uid)));

export const queryReferrersByReferralEmail = async (
  email: string
): Promise<QuerySnapshot<Referrer>> =>
  await getDocs(
    query(referrersCollection, where('referralEmail', '==', email))
  );

export const queryReferrersByCompany = async (
  company: string
): Promise<QuerySnapshot<Referrer>> =>
  await getDocs(query(referrersCollection, where('company', '==', company)));

export const queryReferrersByCompanyId = async (
  companyId: string
): Promise<QuerySnapshot<Referrer>> =>
  await getDocs(
    query(referrersCollection, where('companyId', '==', companyId))
  );

export const activateReferrer = async (id: string): Promise<void> =>
  await updateDoc(doc(referrersCollection, id), { isActivated: true });

export const deactivateReferrer = async (id: string): Promise<void> =>
  await updateDoc(doc(referrersCollection, id), { isActivated: false });

export const getReferrerCreateAtByTimeRange = async (
  start: Date, 
  end: Date
  ): Promise<Timestamp[]> => {
  const q = query(referrersCollection, where('createdAt', '>=', start), where('createdAt', '<=', end));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((doc) => doc.data().createdAt) as Timestamp[];
}

export const getAllReferrersNum = async (): Promise<Number> => {
  const snapshot = await getDocs(referrersCollection);
  return snapshot.size;
}