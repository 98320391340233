import { useState } from 'react';
import TextField from '@mui/material/TextField';
import { createStyles, makeStyles } from '@mui/styles';
import Search from '@mui/icons-material/Search';

const useStyles = makeStyles(() =>
  createStyles({
    inputField: {
      backgroundColor: '#fff',
      border: '1px solid #C6CBD3',
      borderRadius: '30px',
      width: '100%',
    },
  })
);

export const SearchBar: React.FC<{
  cardInfo: Array<{ roomId: string; name: string }>;
  handleSelectedRoom: (room: string, name: string) => void;
  handleInputChange: (filteredRoomIdList: Array<string>) => void;
}> = ({ cardInfo, handleSelectedRoom, handleInputChange }) => {
  const [value, setValue] = useState<string>('');
  const classes = useStyles();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const roomIds = cardInfo
      .filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLocaleLowerCase())
      )
      .map((item) => item.roomId);
    setValue(e.target.value);
    handleInputChange(roomIds);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setValue('');
      handleCallBack();
    }
  };

  const handleCallBack = () => {
    const res = cardInfo.find((item) => item.name === value);
    if (res) {
      handleSelectedRoom(res.roomId, res.name);
    }
  };

  return (
    <TextField
      sx={{
        fieldset: { borderColor: 'transparent' },
      }}
      className={classes.inputField}
      onChange={handleOnChange}
      onKeyDown={handleKeyDown}
      placeholder="搜尋推薦人名稱"
      value={value}
      InputProps={{
        startAdornment: <Search sx={{ marginRight: '8px' }}></Search>,
        style: {
          height: '34px',
          borderRadius: '30px',
          fontSize: '16px',
        },
      }}
    />
  );
};
