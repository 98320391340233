import { Navigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { ChatWindow, RoomList, EmptyState } from './components';
import { createStyles, makeStyles } from '@mui/styles';
import { Conversation } from 'types/conversation';
import { UnreadMessageInfo } from 'types/notification';
import { queryConversationsByUser, getUnreadMessagesByUser } from '../../apis';
import { useAuth } from 'app/ProviderAuth';
import { getReceiver } from 'helper/conversation';
import { useFooter } from 'hooks/useFooter';

const height = 'calc(100vh - 64px)';
const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: height,
      width: '100%',
      display: 'flex',
    },
    leftPanel: {
      padding: '16px 22px',
      backgroundColor: '#F4F6FA',
      maxWidth: '400px',
      width: '30%',
      minWidth: '300px',
      '@media (max-width: 640px)': {
        width: '100%',
        maxWidth: '100%',
        display: (props: { isRightPanelVisible: boolean }) =>
          props.isRightPanelVisible ? 'none' : 'block',
      },
    },
    rightPanel: {
      maxHeight: height,
      width: '100%',
      height: '100vh',
      '@media (max-width: 640px)': {
        display: (props: { isRightPanelVisible: boolean }) =>
          props.isRightPanelVisible ? 'block' : 'none',
      },
    },
    messageTitle: {
      marginBottom: '10px',
    },
  })
);

export const ConversationPage = () => {
  const [isRightPanelVisible, setIsRightPanelVisible] = useState(false);
  const classes = useStyles({ isRightPanelVisible });
  const { showFooter } = useFooter();
  showFooter(false);
  const [conversations, setConversations] =
    useState<Array<Conversation> | null>(null);
  const [roomId, setRoomId] = useState<string | null>(null);
  const [receiver, setReceiver] = useState<string | null>();
  const [receiverId, setReceiverId] = useState<string | null>();
  const [lastUnreadMessageId, setLastUnReadMessageId] = useState<string | null>(
    null
  );
  const [unreadMessages, setUnreadMessages] = useState<{
    [roomId: string]: UnreadMessageInfo;
  } | null>(null);
  const { user, isAdmin } = useAuth();

  useEffect(() => {
    if (user) {
      const fetchConversation = async () => {
        await queryConversationsByUser(user.uid, setConversations);
      };
      const fetchNotification = async () => {
        await getUnreadMessagesByUser(user.uid, setUnreadMessages);
      };
      fetchConversation();
      fetchNotification();
    }
  }, [user]);

  // if (!isAdmin) {
  //   return <Navigate to="/companies" replace />;
  // }
  const handleConversationRoomCallback = (id: string) => {
    setRoomId(id);
    if (conversations) {
      const result = conversations.find((item) => item.roomId === id);
      const sender = result?.participantsId.find((item) => item !== user?.uid);
      if (result !== undefined) {
        setReceiver(getReceiver(result, user?.uid as string));
        setReceiverId(sender);
        setLastUnReadMessageId(
          unreadMessages &&
            unreadMessages[roomId as string]?.lastUnreadMessageId
        );
      }
      setIsRightPanelVisible(true);
    }
  };

  const handleSelectedRoomCallback = (
    room: string | null,
    name: string | null
  ) => {
    if (!room) {
      setIsRightPanelVisible(false);
    }
    setReceiver(name);
    setRoomId(room);
  };

  return (
    <Box className={classes.container}>
      {conversations && conversations.length === 0 ? (
        <EmptyState />
      ) : (
        <>
          <Box className={classes.leftPanel}>
            {conversations && (
              <RoomList
                conversations={conversations}
                unreadMessages={
                  unreadMessages as { [roomId: string]: UnreadMessageInfo }
                }
                userId={user?.uid as string}
                roomId={roomId as string}
                handleSelectedRoom={handleSelectedRoomCallback}
                handleConversationRoom={handleConversationRoomCallback}
              />
            )}
          </Box>
          <Box className={classes.rightPanel}>
            {user && (
              <ChatWindow
                userInfo={{ uid: user.uid, name: user.displayName as string }}
                roomId={roomId as string}
                receiver={receiver as string}
                receiverId={receiverId as string}
                unReadMsgId={lastUnreadMessageId}
                callback={handleSelectedRoomCallback}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};