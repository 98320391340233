import React from 'react';

import { Button, styled } from '@mui/material';
import { GREY1, GREY7, GREY8, WHITE, RED } from 'constants/colors';

interface NavbarButtonProps {
  children: React.ReactNode;
  href: string;
  notificationCount?: number | null;
}

const StyledButton = styled(Button)(() => ({
  position: 'relative',
  padding: '10px 16px',
  backgroundColor: WHITE,
  color: GREY1,
  fontSize: 16,
  lineHeight: '20px',

  '&:hover': {
    backgroundColor: GREY8,
  },

  '&:active': {
    backgroundColor: GREY7,
    fontWeight: 500,
  },
}));

const NotificationBadge = styled('span')(() => ({
  position: 'absolute',
  top: 5,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 16,
  height: 16,
  borderRadius: '50%',
  backgroundColor: RED,
  fontWeight: 'bold',
  color: WHITE,
  fontSize: 12,
}));

export const NavbarButton = ({
  href,
  children,
  notificationCount,
}: NavbarButtonProps) => (
  <StyledButton href={href}>
    {children}
    {notificationCount && (
      <NotificationBadge>{notificationCount}</NotificationBadge>
    )}
  </StyledButton>
);
