import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { SearchBar, MessageCard } from './';
import { Conversation } from 'types/conversation';
import { UnreadMessageInfo } from 'types/notification';
import { getReceiver } from 'helper/conversation';

const useStyles = makeStyles(() =>
  createStyles({
    messageTitle: {
      marginBottom: '10px',
    },
  })
);

export const RoomList: React.FC<{
  conversations: Array<Conversation>;
  unreadMessages: { [roomId: string]: UnreadMessageInfo };
  userId: string;
  roomId: string;
  handleSelectedRoom: (room: string, name: string) => void;
  handleConversationRoom: (id: string) => void;
}> = ({
  conversations,
  unreadMessages,
  userId,
  roomId,
  handleSelectedRoom,
  handleConversationRoom,
}) => {
  const classes = useStyles();
  const [roomList, setRoomList] = useState<Array<Conversation>>(conversations);
  const checkIsRead = (room: string) => {
    if (!unreadMessages) return false;
    return !unreadMessages[room];
  };

  const handleSubmit = (room: string, name: string) => {
    handleSelectedRoom(room, name);
    setRoomList(conversations);
  };

  const handleInputChange = (filteredRoomIdList: Array<string>) => {
    // Filter the room list based on the input
    setRoomList(
      conversations.filter((item) =>
        filteredRoomIdList.includes(item.roomId as string)
      )
    );
  };

  useEffect(() => {
    setRoomList(conversations);
  }, [conversations]);
  return (
    <>
      <Box>
        <Typography variant="h5" className={classes.messageTitle}>
          訊息
        </Typography>
        <SearchBar
          cardInfo={conversations.map((item) => ({
            roomId: item.roomId as string,
            name: getReceiver(item, userId),
          }))}
          handleSelectedRoom={handleSubmit}
          handleInputChange={handleInputChange}
        />
      </Box>
      {roomList.length > 0 && (
        <Box>
          {roomList.map((item) => (
            <Box
              key={item.roomId}
              onClick={() => handleConversationRoom(item.roomId as string)}
            >
              <MessageCard
                avatar=""
                name={getReceiver(item, userId as string)}
                lastMessage={item.lastMessage}
                isRead={checkIsRead(item.roomId as string)}
                active={item.roomId === roomId}
                isMe={item.lastMessage.sender === userId}
              />
            </Box>
          ))}
        </Box>
      )}
    </>
  );
};
