import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Timestamp } from '@firebase/firestore-types';
import { getTimeFromFireStore } from 'helper/conversation';
import Avatar from './Avatar';

type MessageCardProps = {
  avatar: string;
  name: string;
  lastMessage: {
    content: string;
    timestamp: Timestamp;
    type: 'text' | 'file';
  };
  isRead: boolean;
  active: boolean;
  isMe: boolean;
};

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      marginTop: '8px',
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '5px',
      cursor: 'pointer',
    },
    flexBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    rightBox: {
      marginLeft: '16px',
      width: '100%',
    },
    active: {
      backgroundColor: '#E5E8EE',
    },
    text: {
      color: '#222',
      fontSize: '14px',
      marginTop: '4px',
      flex: 'none',
    },
    unread: {
      fontWeight: 700,
    },
    name: {
      fontSize: '18px',
      '@media (max-width: 991px)': {
        fontSize: '16px',
      },
    },
  })
);

export const MessageCard = ({
  avatar,
  name,
  lastMessage,
  isRead,
  active,
  isMe,
}: MessageCardProps) => {
  const [displayTime, setDisplayTime] = useState<string | null>(null);
  const [displayMessage, setDisplayMessage] = useState<string | null>(null);

  const classes = useStyles();
  useEffect(() => {
    if (lastMessage) {
      const { timestamp, content } = lastMessage;
      if (timestamp) {
        setDisplayTime(getTimeFromFireStore(timestamp));
      }
      setDisplayMessage(
        `${content.substring(0, 20)}${content.length > 20 ? '...' : ''}`
      );
    }
  }, [lastMessage]);

  return (
    <Box className={`${classes.card} ${active ? classes.active : ''}`}>
      <Avatar name={name} size="lg" />
      <Box className={classes.rightBox}>
        <Box className={classes.flexBox}>
          <Typography
            className={`${classes.name} ${isRead ? '' : classes.unread}`}
          >
            {name}
          </Typography>
          <Typography variant="body2" className={classes.text}>
            {displayTime}
          </Typography>
        </Box>
        <Typography variant="body2" className={classes.text}>
          {isMe ? '你' : `${name}`}:{' '}
          {lastMessage.type === 'text' ? displayMessage : '已傳送附件'}
        </Typography>
      </Box>
    </Box>
  );
};