import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  getAuth,
  signInWithPopup,
  FacebookAuthProvider,
  GoogleAuthProvider,
  UserCredential,
} from 'firebase/auth';
import {
  getFirestore,
  collection,
  CollectionReference,
  DocumentData,
} from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import {
  COLLECTION_ACTIVITY_LOGS,
  COLLECTION_APPLICATIONS,
  COLLECTION_COMPANIES,
  COLLECTION_REFERRERS,
  COLLECTION_CONVERSATIONS,
  COLLECTION_APPLICANTS,
  COLLECTION_NOTIFICATIONS,
} from 'constants/collections';
import {
  Application,
  Company,
  ActivityLogs,
  Referrer,
  Conversation,
  Applicant,
  Notification,
} from 'types';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(config);

export const analytics = getAnalytics(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const firestore = getFirestore(firebaseApp);

const googleProvider = new GoogleAuthProvider();
export const googleSignIn = (): Promise<UserCredential> =>
  signInWithPopup(auth, googleProvider);

const fbProvider = new FacebookAuthProvider();
export const fbSignIn = (): Promise<UserCredential> =>
  signInWithPopup(auth, fbProvider);

const createCollection = <T = DocumentData>(collectionName: string) =>
  collection(firestore, collectionName) as CollectionReference<T>;

export const db = {
  activityLogs: createCollection<ActivityLogs>(COLLECTION_ACTIVITY_LOGS),
  applications: createCollection<Application>(COLLECTION_APPLICATIONS),
  companies: createCollection<Company>(COLLECTION_COMPANIES),
  referrers: createCollection<Referrer>(COLLECTION_REFERRERS),
  conversations: createCollection<Conversation>(COLLECTION_CONVERSATIONS),
  applicants: createCollection<Applicant>(COLLECTION_APPLICANTS),
  notifications: createCollection<Notification>(COLLECTION_NOTIFICATIONS),
};
