import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { stringAvatar } from 'helper/conversation';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    avatarContainer: {
      borderRadius: '50%',
      background: 'linear-gradient(150deg, #9FE4FE 0%, #C6F5D5 100%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexShrink: 0,
    },
    small: {
      width: '24px',
      height: '24px',
      '@media (max-width: 991px)': {
        width: '18px',
        height: '18px',
      },
    },
    medium: {
      width: '36px',
      height: '36px',
      '& > p': {
        fontSize: '18px',
      },
      '@media (max-width: 991px)': {
        width: '28px',
        height: '28px',
        '& > p': {
          fontSize: '16px',
        },
      },
    },
    large: {
      width: '48px',
      height: '48px',
      '& > p': {
        fontSize: '24px',
      },
      '@media (max-width: 991px)': {
        width: '36px',
        height: '36px',
        '& > p': {
          fontSize: '18px',
        },
      },
    },
  })
);

const Avatar: React.FC<{ name: string; size?: 'sm' | 'md' | 'lg' }> = ({
  name,
  size = 'md',
}) => {
  const classes = useStyles();
  return (
    <Box
      className={clsx(classes.avatarContainer, {
        [classes.small]: size === 'sm',
        [classes.medium]: size === 'md',
        [classes.large]: size === 'lg',
      })}
    >
      <Typography
        style={{
          fontWeight: 500,
        }}
      >
        {stringAvatar(name).children.map((child) => child)}
      </Typography>
    </Box>
  );
};

export default Avatar;
