import React from 'react';
import { ModalProps, Grid, Typography, Link } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Referrer } from 'types/referrer';

import { BaseModal } from './BaseModal';

import avatarImg from 'resource/avatar.png';
import emailImg from 'resource/email.svg';
import linkedinImg from 'resource/linkedin.svg';

const useStyles = makeStyles(() =>
  createStyles({
    mainInfo: {
      display: 'flex',
      alignItems: 'center',
      minWidth: '832px',
    },

    name: {
      marginLeft: '20px',
      marginRight: 'auto',
    },
    emailIcon: {
      marginLeft: '32px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    linkedinIcon: {
      marginLeft: '32px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

type ReferrerModalProps = {
  referrer: Pick<
    Referrer,
    | 'preferredName'
    | 'name'
    | 'linkedIn'
    | 'referralEmail'
    | 'selfIntroduction'
    | 'applicantNote'
    | 'department'
    | 'jobTitle'
    | 'company'
  >;
  onClose: () => void;
} & Omit<ModalProps, 'children'>;

export const ReferrerModal: React.VFC<ReferrerModalProps> = ({
  referrer,
  onClose,
  ...modalProps
}) => {
  const classes = useStyles();

  return (
    <BaseModal title="認識內推人" onClose={onClose} {...modalProps}>
      <div className={classes.mainInfo}>
        <img src={avatarImg} width="40" alt="Avatar icon" />
        <Typography variant="h6" className={classes.name}>
          {referrer.preferredName || referrer.name}
        </Typography>
        {/* <Link
          href={`mailto:${referrer.referralEmail}`}
          target="_blank"
          rel="noopener"
          className={classes.emailIcon}
        >
          <img src={emailImg} alt="Email icon" />
        </Link> */}
        {referrer.linkedIn && (
          <Link
            href={referrer.linkedIn}
            target="_blank"
            rel="noopener"
            className={classes.linkedinIcon}
          >
            <img src={linkedinImg} alt="LinkedIn icon" />
          </Link>
        )}
      </div>

      <Grid container>
        <Grid item xs={12} sm={6}>
          <h4>推薦人職稱</h4>
          <div>
            {referrer.jobTitle} @ {referrer.company}
          </div>
        </Grid>
        {referrer.department && (
          <Grid item xs={12} sm={6}>
            <h4>屬於哪個團隊</h4>
            <div>{referrer.department}</div>
          </Grid>
        )}
      </Grid>

      {referrer.selfIntroduction && (
        <>
          <h4>推薦人簡介</h4>
          <p>{referrer.selfIntroduction}</p>
        </>
      )}
      {referrer.applicantNote && (
        <>
          <h4>給申請人的話</h4>
          <p>{referrer.applicantNote}</p>
        </>
      )}
    </BaseModal>
  );
};
