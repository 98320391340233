import React, { useState, useCallback } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate, useParams } from 'react-router-dom';

import { Application, ApplicationStatus } from 'types';
import { ApplicationInfoCard } from 'components/InfoCard';
import { ApplicationModal, FeedbackModal } from 'components/Modal';
import { Button, ReadonlyTextField } from 'components';
import { getApplication, updateApplication } from 'apis';
import useApplicationReviewMachine from './ApplicationDetailPage.machine';
import { getDisplayName } from 'helper/application';
import { serverTimestamp } from 'firebase/firestore';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      marginTop: '24px',
    },
    title: {
      marginBottom: '16px',
    },
    subTitle: {
      margin: '8px 0px 0px 0px',
    },
    body: { whiteSpace: 'pre-wrap' },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'end',
      gap: '16px',
      padding: '8px 0 16px',
    },
    button: {
      maxWidth: '348px',
      marginTop: '24px',
    },
  })
);

export function ApplicationDetailPage() {
  const { applicationId } = useParams<{
    applicationId: string;
  }>();
  const [state, send] = useApplicationReviewMachine();
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [application, setApplication] = useState<Application>();

  const classes = useStyles();
  const navigate = useNavigate();

  React.useEffect(() => {
    const loadData = async () => {
      if (applicationId) {
        const app = await getApplication(applicationId);
        setApplication(app);
      }
    };
    loadData();
  }, [applicationId]);

  const displayName = application ? getDisplayName(application) : '';

  const renderApplicationDetail = useCallback(() => {
    if (!application) {
      return null;
    }

    const {
      jobLink0,
      jobTitle0,
      jobId0,
      resumeFirebasePath,
      coverLetterFirebasePath,
      portfolioUrl,
      lastName,
      firstName,
      applicantEmail,
      phoneNumber,
      address,
      introduction,
      needSponsorship,
      status,
    } = application;

    return (
      <Container className={classes.container}>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={5}>
            <Box display="grid" gap={1}>
              <Typography variant="h2">審核申請條件</Typography>
              <ApplicationInfoCard application={application} />
              <Typography variant="h3" className={classes.subTitle}>
                職缺資料
              </Typography>
              <ReadonlyTextField
                label="申請的職缺名稱 Position Name"
                value={jobTitle0}
              />
              <ReadonlyTextField
                label="申請的職缺連結 Job Description Link"
                value={jobLink0}
              />
              {jobId0 && (
                <ReadonlyTextField
                  label="申請的職缺編號 Job ID"
                  value={jobId0}
                />
              )}
              {coverLetterFirebasePath && (
                <ReadonlyTextField
                  label="Cover Letter"
                  value={coverLetterFirebasePath}
                />
              )}
              {portfolioUrl && (
                <ReadonlyTextField
                  label="作品集連結 Portfolio Link"
                  value={portfolioUrl}
                />
              )}
              {resumeFirebasePath && (
                <Button href={resumeFirebasePath}>履歷 Resume</Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box display="grid" gap={1}>
              <Typography variant="h3" className={classes.subTitle}>
                申請人資料
              </Typography>

              {lastName && (
                <ReadonlyTextField
                  label="英文姓氏 Last Name"
                  value={lastName}
                />
              )}
              {firstName && (
                <ReadonlyTextField
                  label="英文名字 First Name"
                  value={firstName}
                />
              )}
              <ReadonlyTextField
                label="電子郵件 Email"
                value={applicantEmail}
              />
              {phoneNumber && (
                <ReadonlyTextField
                  label="聯絡電話 Phone Number"
                  value={phoneNumber}
                />
              )}
              {address && (
                <ReadonlyTextField label="居住地址 Address" value={address} />
              )}
              <ReadonlyTextField label="推薦重點" value={introduction} />
              <ReadonlyTextField
                label="需要公司幫忙申請簽證嗎？"
                value={needSponsorship === 'yes' ? '需要' : '不需要'}
              />
            </Box>
          </Grid>
        </Grid>
        {status === ApplicationStatus.REVIEWING && (
          <div className={classes.buttonContainer}>
            <Button color="secondary" onClick={() => send('REJECT')}>
              拒絕內推
            </Button>
            <Button onClick={() => send('APPROVE')}>完成內推</Button>
          </div>
        )}
      </Container>
    );
  }, [application, classes, send]);

  const renderResultPage = useCallback(() => {
    return (
      <Container className={classes.container}>
        <Grid container columnSpacing={4}>
          <Grid item xs={12} md={5}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              gap={2}
            >
              <Typography variant="h2" className={classes.title}>
                感謝你，內推任務已完成！
              </Typography>
              <Typography variant="body1" className={classes.body}>
                我們已寄出信件通知 {displayName} {'\n\n'}
                如果申請人在 LinkedIn
                聯繫你，不妨也可以加個好友聊個天；大家都在海外打拚，別忘了人脈也是實力的一部份
                :D
              </Typography>
              <Button
                onClick={() => navigate('/applications')}
                fullWidth
                className={classes.button}
              >
                繼續內推
              </Button>
              <Button
                color="secondary"
                onClick={() => navigate('/companies')}
                fullWidth
                className={classes.button}
              >
                返回首頁
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }, [displayName, classes, navigate]);

  return (
    <>
      {state.matches('RESULT') ? renderResultPage() : renderApplicationDetail()}
      {application && (
        <ApplicationModal
          application={application}
          onClose={() => {
            setIsReviewModalOpen(false);
          }}
          open={isReviewModalOpen}
        />
      )}
      <FeedbackModal
        name={displayName}
        onClose={() => send('CANCEL')}
        open={state.matches('REVIEWING')}
        isReject={
          state.context.applicationStatus === ApplicationStatus.REJECTED
        }
        onProceed={async (referrerFeedback) => {
          if (!applicationId) {
            return;
          }
          try {
            await updateApplication(applicationId, {
              status: state.context.applicationStatus,
              referrerFeedback,
              statusChangeTimestamp: serverTimestamp(),
            });
            send('CONFIRM');
          } catch (e) {
            console.log('Error updating application, ', e);
          }
        }}
      />
    </>
  );
}
