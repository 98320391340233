import React, { useState } from 'react';
import {
  Typography,
  Box,
  Divider,
  CardContent,
  IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Button } from 'components';
import { WHITE } from 'constants/colors';
import { getDaysAgo, displayDaysAgo } from 'helper/common';
import { Timestamp } from 'firebase/firestore';

const useStyles = makeStyles((theme) => ({
  Card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: WHITE,
    borderRadius: '12px',
    boxShadow: '0 4px 4px 0 rgb(0 0 0 / 25%)',
    maxWidth: '1280px',
    marginBottom: '24px 25px 24px 24px',// up, right, down, left
    padding: '0px',
    minHeight: '200px',
    width: '100%', 
    boxSizing: 'border-box',
    margin: '0 auto', 
    '&:last-child': {
      paddingBottom: '0px', // Ensure padding-bottom is removed
    },
  },
  informationContainer: {//職稱、部門、地點、職位、實習
    display: 'flex',
    borderRadius: '8px 8px 0px 0px',
    backgroundColor: '#F4F6FA',
    paddingTop: '24px',
    paddingBottom: '24px',
    paddingLeft: '28px',
    paddingRight: '28px',
    gap: '24px',
    flexWrap: 'wrap',
  },
  infoBox: {
    textAlign: 'left',
    //content: 'center',
    justifyContent: 'center',
    gap: '24px',
    flex: 1,
    padding: 0, // Ensure no additional padding affects the alignment
    marginLeft: '8px', // Adjusted margin to ensure alignment with the name
    width: '100%',
    boxSizing: 'border-box',
  },
  BoldText: {
    fontfamily: "Noto Sans TC",
    fontWeight: 500,
    fontSize: '16px',
    fontFamily: 'Noto Sans TC',
    textAlign: 'left',
    fontStyle: 'normal',
    lineHeight: '140%',
    alignSelf: 'stretch',
  },
  SecondText: {
    fontWeight: 400,
    color: '#4D5461',
    fontStyle: 'normal',
    lineHeight: '1.4',
    textAlign: 'left',
    marginLeft: '0px', // Ensure left alignment
  },
  NoteText: {
    fontFamily: 'Noto Sans TC',
    fontSize: '16px',
    color: '#141921',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '140%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxHeight: '60px',
    transition: 'max-height 0.3s ease',
    width: '1090px'
  },
  NoteTextExpanded: {
    maxHeight: 'none',
    whiteSpace: 'normal',
    textOverflow: 'clip',
  },
  divider: {
    width: '1px',
    height: '70px',
    backgroundColor: '#C6CBD3',


  },
  content: {
    paddingTop: '24px',
    paddingBottom: '24px',
    paddingLeft: '34px',
    paddingRight: '28px',
    display: 'flex',
    flexDirection: 'column',
    alignself: 'stretch',
    flexGrow: 1,
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    paddingBottom: '24px',
  },
  noteSection: {
    gap: '24px',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    flexGrow: 1,
  },
  showMoreContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    color: '#4D5461',
    fontFamily: 'Noto Sans TC',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    cursor: 'pointer',
  },
  iconButton: {
    display: 'flex',
    width: '24px',
    height: '24px',
    padding: '7.5px 0px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    color: '#4D5461',
    borderRadius: '32px',
    background: 'var(--Interface-Greys-Grey7, #E5E8EE)',
  },
  icon: {
    width: '12px',
    height: '12px',
    flexShrink: 0,
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '16px', // Adjust gap to ensure correct spacing
  },
}));

interface Referrer {
  preferredName?: string;
  name: string;
  department?: string;
  jobTitle: string;
  location?: string;
  applicantNote?: string;
  referredCount?: number;
  applicationCount?: number;
  lastReferredDate?: Timestamp;
  role?: string;
  internship?: string;
  careerPage?: string;
}

interface ReferrerDetailsProps {
  referrer: Referrer;
  onButtonClick: () => void;
}

export const ReferrerDetailsWhole = ({
  referrer,
  onButtonClick,
}: ReferrerDetailsProps) => {
  const classes = useStyles();
  const maxLength = 100;

  const [showFullContent, setShowFullContent] = useState(false);

  const handleToggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const openExternalUrl = () => {
    window.open(referrer.careerPage, '_blank');
  };

  const {
    department = 'NA',
    location = '不限地點',
    role = '不限部門職位',
    internship = '可',
    careerPage,
    applicantNote = '',
    referredCount = 0,
    lastReferredDate,
  } = referrer;

  return (
    <CardContent className={classes.Card}>
      <Box className={classes.informationContainer}>
        <Box className={classes.infoBox}>
          <Typography className={classes.SecondText} fontSize="16px">
            職稱
          </Typography>
          <Typography className={classes.BoldText}>
            {referrer.jobTitle}
          </Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box className={classes.infoBox}>
          <Typography className={classes.SecondText} fontSize="16px">
            所屬部門
          </Typography>
          <Typography className={classes.BoldText}>{department}</Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box className={classes.infoBox}>
          <Typography className={classes.SecondText} fontSize="16px">
            可內推地點
          </Typography>
          <Typography className={classes.BoldText}>{location}</Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box className={classes.infoBox}>
          <Typography className={classes.SecondText} fontSize="16px">
            可內推職位
          </Typography>
          <Typography className={classes.BoldText}>{role}</Typography>
        </Box>
        <Divider orientation="vertical" flexItem className={classes.divider} />
        <Box className={classes.infoBox}>
          <Typography className={classes.SecondText} fontSize="16px">
            可內推實習
          </Typography>
          <Typography className={classes.BoldText}>{internship}</Typography>
        </Box>
      </Box>
      <Box className={classes.content}>
        <Box className={classes.header}>
          <Box gap={1} display="flex" alignItems="center">
            <Typography className={classes.BoldText}>
              {referrer.preferredName || referrer.name}
            </Typography>
            {careerPage && (
              <IconButton
                color="primary"
                sx={{ padding: 0 }}
                onClick={openExternalUrl}
              >
                <img
                  src="/mingcute_external-link-fill.svg"
                  alt="External Link"
                />
              </IconButton>
            )}
          </Box>
          <Box gap={1} display="flex" alignItems="center">
            <Typography
              className={classes.SecondText}
              fontSize="14px"
            >{`已內推 ${referredCount || 0} 人`}</Typography>
            <Typography
              className={classes.SecondText}
              fontSize="14px"
            >{`最近內推時間：${displayDaysAgo(
              getDaysAgo(lastReferredDate)
            )}`}</Typography>
          </Box>
        </Box>
        <Box className={classes.noteSection}>
          
            <Typography
              className={`${classes.NoteText} ${
                showFullContent ? classes.NoteTextExpanded : ''
              }`}
            >
              {applicantNote}
            </Typography>
          
          <Box className={classes.buttonWrapper}>
            <Box className={classes.showMoreContainer} onClick={handleToggleContent}>
              <IconButton className={classes.iconButton}>
                <img
                  src={showFullContent ? '/HideMoreButtonNew.svg' : '/ExpandMoreButtonNew.svg'}
                  alt="Toggle More"
                  className={classes.icon}
                />
              </IconButton>
              <Typography>顯示更多</Typography>
            </Box>
            <Button shape="round" onClick={onButtonClick} sx={{width: '320px', height: '42'}}>
              幫我內推
            </Button>
          </Box>
        </Box>
      </Box>
    </CardContent>
  );
};