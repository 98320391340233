import { conversationsCollection } from 'configs/db';
import {
  doc,
  collection,
  addDoc,
  updateDoc,
  serverTimestamp,
  DocumentSnapshot,
  QuerySnapshot,
  query,
  getDocs,
  getDoc,
  where,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import { Conversation, NameAndId, Message } from 'types';
import { getTimeWithYearFromFireStore } from 'helper/conversation';
import { addNotification } from './notifications';
import { Timestamp } from '@firebase/firestore-types';

/* Conversation apis */
export const queryConversationsByUser = async (
  uid: string,
  setConversations: React.Dispatch<React.SetStateAction<Conversation[] | null>>
) => {
  // Real-time Listening
  const q = query(
    conversationsCollection,
    where('participantsId', 'array-contains', uid)
  );
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const data: Conversation[] = [];
    querySnapshot.forEach((doc) => {
      const obj = {
        roomId: doc.id,
        participantsId: doc.data().participantsId,
        participantsName: doc.data().participantsName,
        lastMessage: doc.data().lastMessage,
      };
      data.push(obj);
    });
    setConversations(data);
  });

  // Return a cleanup function
  return () => {
    unsubscribe();
  };
};

// filter conversation by user id
export const queryConversationsById = async (
  id: string
): Promise<QuerySnapshot<Conversation>> => {
  return await getDocs(
    query(
      conversationsCollection,
      where('participantsId', 'array-contains', id)
    )
  );
};

export const createConversation = async (
  participants: Array<NameAndId>
): Promise<DocumentSnapshot<Conversation>> => {
  const res = await addDoc(conversationsCollection, {
    participantsId: participants.map((item) => item.uid),
    participantsName: participants.map((item) => item.name),
    created: serverTimestamp(),
    lastMessage: {
      sender: '',
      content: '',
      type: 'text',
      timestamp: serverTimestamp(),
    },
  });
  return await getDoc(res);
};
/* Conversation apis */

/* Message apis */

export const addMessage = async (roomId: string, message: Message) => {
  const { sender, content, senderName } = message;
  const docRef = doc(conversationsCollection, roomId);
  const colRef = collection(docRef, 'messages');
  const docSnapshot = await getDoc(docRef);
  const receiver = docSnapshot
    .data()
    ?.participantsId.find((item) => item !== sender) as string;

  const msg = {
    sender,
    senderName,
    content,
    type: 'text' as const,
    timestamp: serverTimestamp(),
  };

  const addedMsg = await addDoc(colRef, msg);

  // Update last message in Conversation Collection
  await updateDoc(docRef, {
    lastMessage: msg,
  });

  // Add notification

  await addNotification({
    receiverId: receiver,
    sender,
    senderName,
    lastUnreadMessageId: addedMsg.id,
    lastUnreadMessageContent: content,
    roomId,
  });
};

export const addFile = async (
  roomId: string,
  file: { sender: string; senderName: string; pdf: File }
) => {
  const { sender, senderName, pdf } = file;
  if (!pdf) {
    return;
  }
  const fileSizeInMB = pdf.size / (1024 * 1024); // convert size to MB
  const docRef = doc(conversationsCollection, roomId);
  const colRef = collection(docRef, 'messages');
  const docSnapshot = await getDoc(docRef);
  const receiver = docSnapshot
    .data()
    ?.participantsId.find((item) => item !== sender) as string;

  if (pdf.type !== 'application/pdf') {
    return;
  }

  if (fileSizeInMB > 5) {
    throw new Error('檔案請小於5 MB');
  }

  // Create a storage reference
  const storage = getStorage();
  const storageRef = ref(
    storage,
    `conversation_pdfs/${roomId}/${pdf.name}_${new Date().getTime()}`
  );

  try {
    // Upload the file to Firebase Storage
    await uploadBytesResumable(storageRef, pdf);

    // Get the download URL
    const downloadURL = await getDownloadURL(storageRef);

  const msg = {
    sender,
    senderName,
    content: pdf.name,
    type: 'file' as const,
    timestamp: serverTimestamp(),
    pdfUrl: downloadURL,
  };


    const addedMsg = await addDoc(colRef, msg);

    // Update last message in Conversation Collection
    await updateDoc(docRef, {
      lastMessage: msg,
    });

  // Add notification
  await addNotification({
    receiverId: receiver,
    sender,
    senderName,
    lastUnreadMessageId: addedMsg.id,
    lastUnreadMessageContent: pdf.name,
    roomId,
  });
  } catch (err) {
    throw new Error('檔案上傳失敗');
  }
};

export const queryMessagesByRoom = async (
  roomId: string,
  setMessagesByDate: React.Dispatch<
    React.SetStateAction<Array<{ date: string; messages: Message[] }>>
  >
) => {
  const docRef = doc(conversationsCollection, roomId);
  const colRef = collection(docRef, 'messages');
  const q = query(colRef, orderBy('timestamp', 'desc'));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const groupedMessages: { [date: string]: Message[] } = {};

    querySnapshot.forEach((doc) => {
      const obj = {
        content: doc.data().content,
        sender: doc.data().sender,
        senderName: doc.data().senderName,
        timestamp: doc.data().timestamp,
        messageId: doc.id,
        type: doc.data().type,
        pdfUrl: doc.data().pdfUrl,
      };

      // Iterate through the messages array and group them by date
      const time = getTimeWithYearFromFireStore(obj.timestamp as Timestamp);

      if (!groupedMessages[time]) {
        groupedMessages[time] = [];
      }
      groupedMessages[time].unshift(obj);
    });

    // Convert the grouped messages object into an array of date/message pairs
    const groupedMessagesArray = Object.keys(groupedMessages).map((date) => ({
      date,
      messages: groupedMessages[date],
    }));

    setMessagesByDate(groupedMessagesArray);
  });

  // Return a cleanup function
  return () => {
    unsubscribe();
  };
};
/* Message apis */
