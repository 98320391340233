import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Button, ControlledField, NexForm, NexOption } from 'components';
import { queryCompanies } from 'apis';
import { ReferrerFormProps, CompanyOptions } from './ReferrerForm.types';
import makeStyles from '@mui/styles/makeStyles';
import { CompanyAutocomplete } from './CompanyAutocomplete';
import { isValidLinkedInUrl } from 'helper/common';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 'fit-content',
  },
  applicationNote: {
    height: '275px',
  },
}));

export const ReferrerForm: React.FC<ReferrerFormProps> = ({
  control,
  errors,
  handleSubmit,
  isSubmitting,
  submitted,
  setValue,
  setIsNewCompany,
  getValues,
}) => {
  const classes = useStyles();
  const [companyOptions, setCompanyOptions] = useState<CompanyOptions[]>([]);
  const hint = [
    '請勿事先對同個職位提交申請',
    '請提供 JOB ID (最多三個)',
    '第三人稱簡介',
    '請附上英文履歷',
  ];
  useEffect(() => {
    async function fetchData() {
      const snapshot = await queryCompanies();
      const data = snapshot.docs.map((doc) => ({
        inputValue: doc.data().company,
        title: doc.data().company,
        id: doc.data().id!,
      }));
      setCompanyOptions(data);
    }
    fetchData();
  }, []);
  const handleChipOnClick = (option: string) => {
    setValue(
      'applicantNote',
      getValues('applicantNote') !== ''
        ? getValues('applicantNote') + ', ' + option
        : option
    );
  };
  return (
    <NexForm gap={4} handleSubmit={handleSubmit}>
      <Typography variant="h3">推薦人註冊資料</Typography>
      <Box display="grid" gap={2.5}>
        <ControlledField
          label="您的名字 - Your Name"
          name="name"
          required
          control={control}
          error={errors.name}
          helperText="您的名字並不會公開在平台上，僅供 NEX 內部留存紀錄"
        />

        <ControlledField
          label="顯示名稱 - Your Display Name"
          name="preferredName"
          control={control}
          required
          error={errors.preferredName}
        />

        <ControlledField
          label="您的職稱 - Title"
          name="jobTitle"
          control={control}
          required
          error={errors.jobTitle}
        />

        <ControlledField
          label="您的所屬部門 - Your Department"
          name="department"
          control={control}
          required
          error={errors.department}
        />

        <CompanyAutocomplete
          label="可幫忙公司名稱 - Available Referral Company"
          name="company"
          control={control}
          required
          error={errors.company}
          setValue={setValue}
          setIsNewCompany={setIsNewCompany}
          companyOptions={companyOptions}
        />

        <ControlledField
          label="可幫忙內推地點 - Available Referral Location"
          name="location"
          control={control}
          required
          error={errors.location}
        />

        <ControlledField
          label="可幫忙內推部門或職位 - Available Referral Role"
          name="role"
          control={control}
          required
          error={errors.role}
        />

        <ControlledField
          label="LinkedIn"
          name="linkedIn"
          control={control}
          required
          validationRule={isValidLinkedInUrl}
          error={errors.linkedIn}
        />

        <ControlledField
          label="內推信箱 - Referral Email"
          name="referralEmail"
          control={control}
          required
          error={errors.referralEmail}
        />
        <NexOption
          label="是否顯示LinkedIn 在前台 (建議勾選顯示已增加托展人脈的機會)"
          name="showLinkedIn"
          control={control}
          options={[
            { label: '是', value: 'yes' },
            { label: '否', value: 'no' },
          ]}
          error={errors.showLinkedIn}
          required
        />

        <NexOption
          label="公司是否提供簽證協助 - Does Company Sponsor Visa?"
          name="sponsorship"
          control={control}
          options={[
            { label: '是', value: 'yes' },
            { label: '否', value: 'no' },
          ]}
          error={errors.sponsorship}
          required
        />

        <NexOption
          label="是否協助內推實習 - Does Company Offer Internship?"
          name="internship"
          control={control}
          options={[
            { label: '是', value: 'yes' },
            { label: '否', value: 'no' },
          ]}
          error={errors.internship}
          required
        />

        <ControlledField
          label="公司官方求職網頁 - Company Career Page"
          name="careerPage"
          control={control}
          error={errors.careerPage}
        />

        <ControlledField
          label="簡介 - Self Introduction"
          name="selfIntroduction"
          control={control}
          error={errors.selfIntroduction}
          multiline
          rows={10}
        />

        <ControlledField
          label="給求職者的話 - Note to Applicants"
          name="applicantNote"
          control={control}
          error={errors.applicantNote}
          multiline
          rows={10}
          options={hint}
          handleChipOnClick={handleChipOnClick}
        />
        <Button
          disabled={isSubmitting || submitted}
          type="submit"
          color="primary"
          className={classes.button}
          sx={{ width: 'fit-content' }}
        >
          送出
        </Button>
      </Box>
    </NexForm>
  );
};
