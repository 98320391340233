import React, { useState } from 'react';
import { Box, TextField } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { createConversation, addMessage } from 'apis';
import { NameAndId } from 'types';
import { UploadFileModal } from 'components/Modal';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
      margin: '16px 0',
      padding: '0 16px',
      gap: '12px',
    },
    fileContainer: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& input[type="file"]::-webkit-file-upload-button': {
        cursor: 'pointer',
      },
    },
    input: {
      width: '100%',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#C6CBD3', // Hide the default border
        },
        '&:hover fieldset': {
          borderColor: '#C6CBD3', // Hide the default border on hover
        },
        '&.Mui-focused fieldset': {
          borderColor: 'transparent', // Hide the default border when focused
        },
        '&.Mui-focused::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          borderRadius: 'inherit',
          padding: '2px', // Adjust the padding to match the border width
          background: 'linear-gradient(90deg, #6FD7FD, #47DE78)',
          '-webkit-mask':
            'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
          '-webkit-mask-composite': 'destination-out',
          'mask-composite': 'exclude',
        },
      },
    },
    icon: {
      cursor: 'pointer',
    },
  })
);

function checkString(inputString: string) {
  // Check if the string only contains '\n' or is empty
  return /^(\n*|\s*)$/.test(inputString);
}

export const ConversationInputBar: React.FC<{
  roomId: string;
  participants: Array<NameAndId> | null;
  sender: string;
  senderName: string;
}> = React.memo(({ roomId, participants, sender, senderName }) => {
  const classes = useStyles();
  const [content, setContent] = useState<string>('');
  const [open, setOpen] = useState(false);
  const isRoomEmpty = !roomId && participants !== null;

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // Detects Enter button on keyboard
    if (e.key === 'Enter' && e.shiftKey) {
      // add new line
      setContent(content + '\n');
    } else if (e.key === 'Enter') {
      // Create an new room
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    if (isRoomEmpty) {
      const res = await createConversation(participants);
      await sendMessage(res.id);
    } else {
      await sendMessage(roomId);
    }
  };

  const sendMessage = async (id: string) => {
    if (!checkString(content)) {
      const data = {
        sender,
        senderName,
        content: content,
        type: 'text' as const,
      };
      addMessage(id, data);
      setContent('');
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  };

  return (
    <Box className={classes.container}>
      <img
        className={classes.icon}
        src={'/file.svg'}
        alt="file"
        onClick={() => setOpen(true)}
      />
      <TextField
        id="outlined-textarea"
        className={classes.input}
        value={content}
        multiline
        minRows={1}
        maxRows={2}
        onKeyDown={handleKeyDown}
        onChange={handleOnChange}
        InputProps={{
          style: {
            borderRadius: '10px',
            padding: '8px 16px',
            overflow: 'hidden',
          },
          onKeyDown: (e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          },
          endAdornment: (
            <img
              src={'/send.svg'}
              className={classes.icon}
              alt="send"
              onClick={handleSubmit}
            />
          ),
        }}
      />
      {open && (
        <UploadFileModal
          open={open}
          onClose={() => setOpen((prev) => !prev)}
          roomData={{
            isRoomEmpty,
            roomId,
            participants: participants as Array<NameAndId>,
            sender,
            senderName,
          }}
        />
      )}
    </Box>
  );
});

export default React.memo(ConversationInputBar);
