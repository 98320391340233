import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import Avatar from './Avatar';

const useStyles = makeStyles(() =>
  createStyles({
    dialogueBox: {
      padding: '10px 16px',
      borderBottom: '1px solid #ccc',
      boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.15)',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    title: {
      fontWeight: 700,
    },
    arrow: {
      '@media (max-width: 640px)': {
        border: 'solid black',
        borderWidth: '0 3px 3px 0',
        display: 'inline-block',
        padding: '3px',
        transform: 'rotate(135deg)',
        '-webkit-transform': 'rotate(135deg)',
        cursor: 'pointer',
      },
    },
  })
);

export const DialogTitle: React.FC<{
  receiver: string;
  goBack: () => void;
}> = ({ receiver, goBack }) => {
  const classes = useStyles();
  return (
    <Box className={classes.dialogueBox}>
      {receiver && (
        <>
          <i className={classes.arrow} onClick={goBack}></i>
          <Avatar name={receiver} />
          <Typography className={classes.title}>{receiver}</Typography>
        </>
      )}
    </Box>
  );
};