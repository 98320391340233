import React, { useEffect, useState } from 'react';
import {
  Dialog,
  Typography,
  Box,
  Divider,
  ModalProps
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { NameAndId } from 'types';
import { createConversation, addFile } from 'apis';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '50vh',
      maxWidth: '400px',
      minWidth: '250px',
      padding: '16px 20px',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    button: {
      position: 'relative',
      marginTop: '22px',
      width: '100%',
      height: '32px',
      fontWeight: 500,
      fontSize: '16px',
      background: 'linear-gradient(150deg, #9FE4FE 0%, #C6F5D5 100%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderStyle: 'none',
      cursor: 'pointer',
      borderRadius: '2px',
    },
    fileInput: {
      position: 'absolute',
      left: 0,
      top: 0,
      opacity: 0,
      width: '100%',
      height: '100%',
      cursor: 'pointer',
    },
  })
);

type UploadFileModalProps = {
  roomData: {
    isRoomEmpty: boolean;
    roomId: string;
    participants: Array<NameAndId>;
    sender: string;
    senderName: string;
  };
  onClose: () => void;
} & Omit<ModalProps, 'children'>;

export const UploadFileModal: React.VFC<UploadFileModalProps> = ({
  roomData,
  onClose,
  ...modalProps
}) => {
  const classes = useStyles();
  const [error, setError] = useState<string | null>(null);
  const { isRoomEmpty, roomId, participants, sender } = roomData;

  const callAddFile = async (
    roomId: string,
    data: { sender: string; senderName: string; pdf: File }
  ) => {
    try {
      await addFile(roomId, data);
      onClose();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      }
    }
  };

  const sendFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];

    const data = {
      sender: sender,
      senderName: roomData.senderName,
      pdf: file,
    };

    if (isRoomEmpty) {
      const res = await createConversation(participants);
      await callAddFile(res.id, data);
    } else {
      await callAddFile(roomId, data);
    }
  };

  return (
    <Dialog onClose={onClose} {...modalProps}>
      <Box className={classes.container}>
        <Typography sx={{ fontSize: 16 }}>選取附件</Typography>
        <Divider sx={{ mt: 1, mb: 1.5 }} />
        <Box>
          {!error ? (
            <>
              <Typography sx={{ fontSize: 14, mb: 1 }}>•只限PDF檔案</Typography>
              <Typography sx={{ fontSize: 14 }}>•檔案請小於5 MB</Typography>
            </>
          ) : (
            <>
              <Typography sx={{ fontSize: 14, display: 'inline' }}>
                X{' '}
              </Typography>
              <Typography sx={{ fontSize: 14, display: 'inline' }}>
                {error}
              </Typography>
            </>
          )}
        </Box>
        <button className={classes.button}>
          選取附件
          <input
            type="file"
            className={classes.fileInput}
            onChange={sendFile}
            accept=".pdf"
          />{' '}
        </button>
      </Box>
    </Dialog>
  );
};
