import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { Message } from 'types/conversation';
import { useAuth } from 'app/ProviderAuth';
import { Timestamp } from '@firebase/firestore-types';
import { removeNotification, queryMessagesByRoom } from 'apis';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
    },
    reverse: {
      // For dialogue displays from the bottom
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    room: {
      overflowY: 'auto',
    },
    dialogueBlock: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'start',
      margin: '0 16px 16px 16px',
      '& > div': {
        background:
          'linear-gradient(#F4F6FA 0 0) padding-box, linear-gradient(to right, #6fd7fd, #47de78) border-box',
        color: '#313149',
        border: '1px solid transparent',
        borderRadius: '5px',
      },
    },
    selfBlock: {
      alignItems: 'end',
    },
    messageBox: {
      padding: '8px 12px',
      minWidth: '40%',
      maxWidth: '80%',
    },
    pdfBox: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    text: {
      wordBreak: 'break-all',
      fontSize: '14px',
    },
    unread: {
      color: '#cccccc',
      alignSelf: 'center',
      marginBottom: '8px',
    },
    timestamp: {
      fontSize: '12px',
      textAlign: 'end',
      marginTop: '8px',
    },
    date: {
      textAlign: 'center',
      fontSize: '14px',
      margin: '16px 0 8px 0',
    },
  })
);

const getLocalTime = (timestamp: Timestamp) => {
  if (!timestamp) return '';
  const date = new Date(timestamp.seconds * 1000);
  const timeString = date.toLocaleString();
  return `${timeString.substring(
    timeString.indexOf(',') + 2,
    timeString.lastIndexOf(':')
  )} ${timeString.slice(-2)}`;
};

export const Messages: React.FC<{
  uid: string;
  roomId: string;
  receiverId: string;
  unReadMsgId: string | null;
}> = ({ uid, roomId, receiverId, unReadMsgId }) => {
  const classes = useStyles();
  const [messagesByDate, setMessagesByDate] = useState<
    Array<{ date: string; messages: Message[] }>
  >([]);
  const { user } = useAuth();

  useEffect(() => {
    let cleanup: (() => void) | undefined;
    if (roomId) {
      const fetchMessages = async () => {
        cleanup = await queryMessagesByRoom(roomId, setMessagesByDate);
      };
      fetchMessages();
    }
    return () => cleanup && cleanup();
  }, [roomId]);

  useEffect(() => {
    // Mark the message as read if the user is in the room
    const updateStatus = async () => {
      if (user) {
        await removeNotification(user.uid, roomId);
      }
    };
    const interval = setTimeout(() => {
      updateStatus();
    }, 0);
    return () => clearInterval(interval);
  }, [messagesByDate]);

  return (
    <Box className={`${classes.container} ${classes.reverse} ${classes.room}`}>
      {messagesByDate &&
        messagesByDate.map((item, index) => (
          <Box key={index}>
            <Typography variant="body2" className={classes.date}>
              {item.date}
            </Typography>
            {item.messages.map((message, idx) => (
              <Box
                className={`${classes.dialogueBlock} ${
                  message.sender === uid ? classes.selfBlock : ''
                }`}
                key={idx}
              >
                {unReadMsgId && unReadMsgId === message.messageId && (
                  <Typography className={classes.unread} variant="body2">
                    - Unread Message -
                  </Typography>
                )}

                <Box className={classes.messageBox}>
                  {message.type === 'text' ? (
                    <Typography variant="body2" className={classes.text}>
                      {message.content}
                    </Typography>
                  ) : (
                    <Box
                      className={classes.pdfBox}
                      onClick={() => {
                        window.open(message.pdfUrl, '_blank');
                      }}
                    >
                      <img src="/pdf.svg" alt="file" />
                      <Typography variant="body2" className={classes.text}>
                        {message.content}
                      </Typography>
                    </Box>
                  )}
                  <Typography className={classes.timestamp}>
                    {getLocalTime(message.timestamp as Timestamp)}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        ))}
    </Box>
  );
};