import React from 'react';
import { Box, Typography } from '@mui/material';
import { Button, ControlledField, NexForm, NexOption } from 'components';
import {
  Control,
  FieldErrors,
} from 'react-hook-form';
import { FormEvent } from 'react';
import { Applicant } from 'types';
import makeStyles from '@mui/styles/makeStyles';
import { isValidLinkedInUrl } from 'helper/common';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 'fit-content',
  },
  applicationNote: {
    height: '275px',
  },
}));

export interface ApplicantFormProps {
  control: Control<Applicant>;
  errors: FieldErrors<Applicant>;
  handleSubmit: (event: FormEvent) => void;
  isSubmitting: boolean;
  submitted: boolean;
}

export const ApplicantForm: React.FC<ApplicantFormProps> = ({
  control,
  errors,
  handleSubmit,
  isSubmitting,
  submitted,
}) => {
  const classes = useStyles();

  return (
    <NexForm gap={4} handleSubmit={handleSubmit}>
      <Box> 
        <Typography variant="h3">編輯申請資料</Typography>
        <Typography
            variant="subtitle1"
            color="textSecondary"
            style={{ fontStyle: 'italic' }}
          >
            Note: 這些資料將會用於申請內推時自動填入表單中
        </Typography>
      </Box>
      <Box display="grid" gap={2.5}>
        <ControlledField
          label="英文姓氏 - Your Last Name"
          name="lastName"
          control={control}
          required
          error={errors.lastName}
        />

        <ControlledField
          label="英文名字 - Your First Name"
          name="firstName"
          required
          control={control}
          error={errors.firstName}
          helperText="您的名字並不會公開在平台上，僅供 NEX 內部留存紀錄"
        />

        <ControlledField
          label="電子信箱 - Email"
          name="email"
          control={control}
          error={errors.email}
          required
        />

        <ControlledField
          label="連絡電話 - Phone"
          name="phone"
          control={control}
          error={errors.phone}
        />

        <ControlledField
          label="居住地址 - Address"
          name="address"
          control={control}
          error={errors.address}
        />

        <ControlledField
          label="LinkedIn"
          name="linkedIn"
          control={control}
          required
          validationRule={isValidLinkedInUrl}
          error={errors.linkedIn}
        />

        <NexOption
          label="需要公司幫忙申請簽證嗎 - Visa Sponsorship?"
          name="needSponsorship"
          control={control}
          options={[
            { label: '是', value: 'yes' },
            { label: '否', value: 'no' },
          ]}
          error={errors.needSponsorship}
        />

        <Button
          disabled={isSubmitting || submitted}
          type="submit"
          color="primary"
          className={classes.button}
          sx={{ width: 'fit-content' }}
        >
          更新
        </Button>
      </Box>
    </NexForm>
  );
};
