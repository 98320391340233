import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import ForumIcon from '@mui/icons-material/Forum';
import { Button } from 'components';
import { ConversationInputBar } from './ConversationInputBar';
import { DialogTitle } from './DialogTitle';
import { ReferrerListModal } from 'components/Modal';
import { NameAndId } from 'types/conversation';
import { Messages } from './Messages';
import { queryConversationsById } from 'apis';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    startConversationBox: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    button: {
      fontSize: '16px',
      marginTop: '16px',
    },
    icon: {
      fontSize: '50px',
    },
  })
);

type ReferrerModalListProps = {
  userInfo: NameAndId;
  roomId: string;
  receiver: string;
  receiverId: string;
  unReadMsgId: string | null;
  callback: (room: string | null, name: string | null) => void;
};

export const ChatWindow: React.FC<ReferrerModalListProps> = ({
  userInfo,
  roomId,
  receiver,
  receiverId,
  unReadMsgId,
  callback,
}) => {
  const [open, setOpen] = useState(false);
  const [participants, setParticipants] = useState<Array<NameAndId>>([]);
  const classes = useStyles();

  const selectReferrer = async (refInfo: NameAndId) => {
    setOpen(false);
    // TODO: Bug here, it only checks receiverId, but not senderId
    const res = await queryConversationsById(refInfo.uid);
    // If a room exist already, call callback function
    if (!res.empty) {
      callback(res.docs[0].id, refInfo.name);
    } else {
      setParticipants([refInfo, userInfo]);
    }
  };

  return (
    <>
      {!roomId && participants.length === 0 ? (
        <Box className={`${classes.startConversationBox} ${classes.container}`}>
          <ForumIcon className={classes.icon} />
          <Typography variant="h2">Start new message</Typography>
          <Button className={classes.button} onClick={() => setOpen(true)}>
            Write a message
          </Button>
          <ReferrerListModal
            callback={selectReferrer}
            open={open}
            onClose={() => setOpen((prev) => !prev)}
          />
        </Box>
      ) : (
        <Box className={classes.container}>
          {
            <>
              <DialogTitle
                receiver={receiver}
                goBack={() => callback(null, null)}
              />
              <Messages
                uid={userInfo.uid}
                roomId={roomId}
                receiverId={receiverId}
                unReadMsgId={unReadMsgId}
              />
              <ConversationInputBar
                roomId={roomId}
                participants={participants}
                sender={userInfo.uid}
                senderName={userInfo.name}
              />
            </>
          }
        </Box>
      )}
    </>
  );
};