import { Timestamp } from 'firebase/firestore';

import dayjs from 'dayjs';

export const getDaysAgo = (timestamp?: Timestamp) => {
  if (!timestamp) {
    return undefined;
  }
  return dayjs().diff(dayjs(timestamp.toDate()), 'day');
};

export const displayDaysAgo = (daysAgo?: number) => {
  if (daysAgo === undefined) {
    return '-';
  }
  if (daysAgo === 0) {
    return '今天';
  }
  if (daysAgo > 30) {
    return '數週前';
  }

  return daysAgo + ' 天前';
};

export const getInitializedName = (fullName?: string) => {
  if (!fullName) {
    return null;
  }
  const [firstName, lastName] = fullName.split(' ');
  let initializedName = firstName[0] + '.';
  if (lastName) {
    initializedName += ' ' + lastName;
  }
  return initializedName;
};

export const isValidLinkedInUrl = (url: string): boolean | string => {
  const linkedInRegex = /^https:\/\/www\.linkedin\.com\/in\/.*$/i;
  return linkedInRegex.test(url.trim()) || 'LinkedIn 連結錯誤, 有效的格式為 https://www.linkedin.com/in';;
};