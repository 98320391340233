import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Box, Container, Snackbar } from '@mui/material';
import { ConfirmModal } from 'components/Modal';
import {
  createCompany,
  queryCompanies,
  queryReferrersByUid,
  updateReferrer,
  getCompanyDocById,
  activateReferrer,
  deactivateReferrer,
} from 'apis';

import { useAuth } from 'app/ProviderAuth';
import { Button } from 'components';
import { ReferrerForm } from '../components/ReferrerForm';
import { Referrer } from 'types';

export const EditReferrerProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [userDocID, setUserDocID] = useState('');
  const [isNewCompany, setIsNewCompany] = useState(true);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [msgBoxOpen, setMsgBoxOpen] = useState(false);
  const [isReferrerActive, setIsReferrerActive] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting, errors },
    getValues,
    setValue,
  } = useForm<Referrer>({
    defaultValues: {
      companyId: '',
      name: '',
      preferredName: '',
      company: '',
      department: '',
      jobTitle: '',
      referralEmail: '',
      sponsorship: '',
      showLinkedIn: '',
      internship: '',
      linkedIn: '',
      selfIntroduction: '',
      applicantNote: '',
      location: '',
      role: '',
      careerPage: '',
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    if (user) {
      const fetchData = async function () {
        const profileSnapshot = await queryReferrersByUid(user.uid);
        const companiesSnapshot = await queryCompanies();
        const profile = profileSnapshot.docs[0].data();

        setUserDocID(profileSnapshot.docs[0].id);
        setIsReferrerActive(profile.isActivated ?? true);
        setMsgBoxOpen(!(profile.isActivated ?? true));
        const companyDoc = companiesSnapshot.docs
          .filter((doc) => doc.data().company === profile.company)[0]
          .data();
        const {
          name,
          preferredName = name,
          company,
          companyId,
          department = '',
          jobTitle,
          referralEmail,
          sponsorship = profile.sponsorship || companyDoc.sponsorship,
          showLinkedIn = 'yes',
          internship = profile.internship || 'yes',
          linkedIn = '',
          selfIntroduction = '',
          applicantNote = '',
          location = '',
          role = '',
          careerPage = '',
        } = profile;
        setValue('name', name);
        setValue('preferredName', preferredName);
        setValue('company', company);
        setValue('companyId', companyId);
        setValue('department', department);
        setValue('jobTitle', jobTitle);
        setValue('referralEmail', referralEmail);
        setValue('sponsorship', sponsorship);
        setValue('showLinkedIn', showLinkedIn);
        setValue('internship', internship);
        setValue('linkedIn', linkedIn);
        setValue('selfIntroduction', selfIntroduction);
        setValue('applicantNote', applicantNote);
        setValue('location', location);
        setValue('role', role);
        setValue('careerPage', careerPage);
      };

      fetchData();
    }
  }, [user, setValue]);

  const onSubmit: SubmitHandler<Referrer> = async (data) => {
    const referrerData: Referrer = data as Referrer;
    referrerData.company = referrerData.company.trim();
    referrerData.location = referrerData.location?.trim();
    referrerData.referralEmail = referrerData.referralEmail.trim();
    referrerData.preferredName = referrerData.preferredName
      ? referrerData.preferredName.trim()
      : referrerData.name.trim();
    await updateReferrer(userDocID, referrerData);

    var companyDoc = await getCompanyDocById(referrerData.companyId);
    if (isNewCompany && !companyDoc.exists()) {
      const { company, companyId, sponsorship } = referrerData;
      companyDoc = await createCompany(company, companyId, sponsorship!);
    }

    setSnackBarOpen(true);
  };

  const handleActivate = () => {
    if (isReferrerActive === false) {
      activateReferrer(userDocID);
      setIsReferrerActive(true);
    } else {
      setMsgBoxOpen(true);
    }
  };

  const handleConfirm = (confirm?: boolean) => {
    if (confirm) {
      if (isReferrerActive) {
        deactivateReferrer(userDocID);
        navigate('/companies');
      } else {
        activateReferrer(userDocID);
        setIsReferrerActive(true);
      }
    }
    setMsgBoxOpen(false);
  };
  const backToCompany = () => {
    navigate('/companies');
  };
  return (
    <Box my={5}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackBarOpen}
        message="成功更新推薦人資料"
        action={
          <Button color="secondary" onClick={backToCompany}>
            回首頁
          </Button>
        }
      />
      <Container maxWidth="md" sx={{ my: 4 }}>
        <ReferrerForm
          control={control}
          errors={errors}
          handleSubmit={handleSubmit(onSubmit)}
          isSubmitting={isSubmitting}
          submitted={snackBarOpen}
          setIsNewCompany={setIsNewCompany}
          setValue={setValue}
          getValues={getValues}
        />
        <Button onClick={handleActivate} sx={{ mt: 2.5 }} color="secondary">
          {isReferrerActive ? '辭去內推者身分' : '重新啟用內推者身分'}
        </Button>
        <ConfirmModal
          title={isReferrerActive ? '確認' : '提醒'}
          name={
            isReferrerActive
              ? '確定要辭去內推者身分嗎?\n確認後會自動拒絕所有申請'
              : '您已經暫停內推者身分，是否要重新啟用內推者身分'
          }
          open={msgBoxOpen}
          onClose={() => setMsgBoxOpen(false)}
          onProceed={handleConfirm}
        />
      </Container>
    </Box>
  );
};
