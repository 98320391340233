import { Box, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => {
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    },
    logoImage: {
      objectFit: 'contain',
      height: '50px',
      verticalAlign: 'middle',
      marginBottom: '20px',
    },
    text: {
      fontWeight: 700,
    },
  });
});

export const EmptyState: React.FC = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <img
        alt="NEX Work"
        src="/nex-work-logo.svg"
        className={classes.logoImage}
      />
      <Typography className={classes.text}>
        當你送出或收到內推申請的時候，與推薦者或申請者的聊天室會自動建立
      </Typography>
    </Box>
  );
};
