import React, { useState } from 'react';
import { Box, Link, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { Referrer } from 'types';
import { BaseInfoCard } from 'components/InfoCard';
import { ReferrerModal } from 'components/Modal';
import { EmailIcon, LinkedInIcon } from 'components/icons';

import avatarImg from 'resource/avatar.png';

type Props = {
  referrer: Pick<
    Referrer,
    | 'uid'
    | 'preferredName'
    | 'name'
    | 'linkedIn'
    | 'referralEmail'
    | 'selfIntroduction'
    | 'applicantNote'
    | 'department'
    | 'jobTitle'
    | 'company'
  >;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(2),
    },
    heading: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    selfIntro: {
      display: '-webkit-box',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
    applicantNote: {
      display: '-webkit-box',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
    },
    link: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

export const ReferrerInfoCard: React.FC<Props> = ({ referrer }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const classes = useStyles();

  function handleModalOpen() {
    setIsModalOpen(true);
  }
  const {
    preferredName,
    name,
    linkedIn,
    referralEmail,
    selfIntroduction,
    applicantNote,
    department,
    jobTitle,
    company,
  } = referrer;
  return (
    <BaseInfoCard className={classes.root}>
      <Box display="flex" mb={3}>
        <Box mr={2.5}>
          <img src={avatarImg} width="40" alt="Avatar icon" />
        </Box>
        <Typography variant="h2" color="textPrimary">
          {preferredName || name}
        </Typography>
      </Box>
      <Box>
        {/* {Boolean(referralEmail) && (
          <Link href={`mailto:${referralEmail}`} target="_blank">
            <EmailIcon />
          </Link>
        )} */}
        {Boolean(linkedIn) && (
          <Link href={linkedIn} target="_blank">
            <LinkedInIcon />
          </Link>
        )}
      </Box>

      {jobTitle && (
        <Box>
          <Typography variant="h3" className={classes.heading}>
            推薦人職稱
          </Typography>
          <Typography>
            {jobTitle} @ {company}
          </Typography>
        </Box>
      )}
      {department && (
        <Box>
          <Typography variant="h3" className={classes.heading}>
            屬於哪個團隊
          </Typography>
          <Typography>{department}</Typography>
        </Box>
      )}
      {selfIntroduction && (
        <Box>
          <Typography variant="h3" className={classes.heading}>
            推薦人簡介
          </Typography>
          <Typography className={classes.selfIntro}>
            {selfIntroduction}
          </Typography>
          <Link className={classes.link} onClick={handleModalOpen}>
            查看細節
          </Link>
        </Box>
      )}
      {applicantNote && (
        <Box>
          <Typography variant="h3" className={classes.heading}>
            給申請人的話
          </Typography>
          <Typography className={classes.applicantNote}>
            {applicantNote}
          </Typography>
          <Link className={classes.link} onClick={handleModalOpen}>
            查看細節
          </Link>
        </Box>
      )}
      <ReferrerModal
        open={isModalOpen}
        referrer={referrer}
        onClose={() => setIsModalOpen(false)}
      />
    </BaseInfoCard>
  );
};
