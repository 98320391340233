import { notificationsCollection } from 'configs/db';
import {
  doc,
  setDoc,
  updateDoc,
  serverTimestamp,
  getDoc,
  onSnapshot,
  deleteField,
  deleteDoc,
} from 'firebase/firestore';
import { UnreadMessageInfo } from 'types/notification';

// receiver id is the document id
export const addNotification = async (params: {
  receiverId: string;
  sender: string;
  senderName: string;
  lastUnreadMessageId: string;
  lastUnreadMessageContent: string;
  roomId: string;
}): Promise<void> => {
  const {
    receiverId,
    sender,
    senderName,
    lastUnreadMessageId,
    lastUnreadMessageContent,
    roomId,
  } = params;
  const docRef = doc(notificationsCollection, receiverId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    const existingMessage = data?.unreadMessages[roomId];

    if (existingMessage) {
      // If the sender already exists in the unreadMessages, remove the existing object and add a new one
      await updateDoc(docRef, {
        [`unreadMessages.${roomId}`]: deleteField(),
      });
    }
    await updateDoc(docRef, {
      unreadMessages: {
        [roomId]: {
          sender,
          senderName,
          lastUnreadMessageId,
          lastUnreadMessageContent,
        } as any, // TODO: Fix this any
      },
    });
  } else {
    const data = docSnap.data();
    const count = data?.messageCount;
    // If the document does not exist, create a new one
    await setDoc(docRef, {
      messageCount: count ? count + 1 : 1,
      unreadMessages: {
        [roomId]: {
          sender,
          senderName,
          lastUnreadMessageId,
          lastUnreadMessageContent,
        },
      },
      created: serverTimestamp(),
    });
  }
};

// Remove notification
export const removeNotification = async (
  userId: string,
  roomId: string
): Promise<void> => {
  const docRef = doc(notificationsCollection, userId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    const data = docSnap.data();
    const existingMessage = data?.unreadMessages[roomId];

    if (existingMessage) {
      if (data.messageCount === 1) {
        // If messageCount is 1, remove the entire document
        await deleteDoc(docRef);
      } else {
        // Otherwise, remove the specific message and decrement the messageCount
        await updateDoc(docRef, {
          messageCount: data.messageCount - 1,
          [`unreadMessages.${roomId}`]: deleteField() as any,
        });
      }
    }
  }
};

export const getUnreadMessagesByUser = async (
  userId: string,
  setUnreadMessages: React.Dispatch<
    React.SetStateAction<{ [roomId: string]: UnreadMessageInfo } | null>
  >
) => {
  const docRef = doc(notificationsCollection, userId);

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      setUnreadMessages(data?.unreadMessages);
    }
  });

  // Return a cleanup function
  return () => {
    unsubscribe();
  };
};

export const getNotificationCountByUser = async (
  userId: string,
  setNotificationCount: React.Dispatch<React.SetStateAction<number>>
) => {
  const docRef = doc(notificationsCollection, userId);

  const unsubscribe = onSnapshot(docRef, (docSnap) => {
    if (docSnap.exists()) {
      const data = docSnap.data();
      setNotificationCount(data?.messageCount || 0);
    } else {
      setNotificationCount(0);
    }
  });

  // Return a cleanup function
  return () => {
    unsubscribe();
  };
};
